import { render, staticRenderFns } from "./index.vue?vue&type=template&id=8ab1b2c0"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Narratorcomp: require('/var/www/frontend/components/ui/Narratorcomp.vue').default,Narrator: require('/var/www/frontend/components/ui/Narrator.vue').default,Faq: require('/var/www/frontend/components/ui/Faq.vue').default,BigLinks: require('/var/www/frontend/components/ui/BigLinks.vue').default})
