
import { mapGetters, mapMutations } from 'vuex'
import * as ov from '@/constants/constants'
import { SITE_CLOSED } from '@/constants/constants.js';

export default {
    head() {
        return {
            title: this.pageData.metatitle ? this.pageData.metatitle : this.pageData.h1,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.pageData.metadesc,
                },

            ],
            bodyAttrs: {
                // class: ['home']
            },
            // script: [
            //     {
            //         src: '/assets/scripts/simpleParallax.min.js',// только на главной
            //     },
            // ],

        }
    },


    data() {
        return {
            pageData: null,
            active_genre: 0,

        };
    },

    asyncData(context) {
        if (!SITE_CLOSED && context.store.getters.isAuthenticated) {
            if (context.store.state.auth.user.role == 'agent') {
                context.redirect(301, context.localePath('profile-settings'))
                return
            }
            context.redirect(301, context.localePath('profile-orders'))
            return
        }
        // console.log(context.store.state.home)
        if (!context.store.state.home) {
            return context.$axios
                .get('/home')
                .then((res) => {
                    context.store.commit('saveHome', res.data)
                    return {
                        pageData: res.data,
                    }
                })
        } else {
            return {
                pageData: context.store.state.home,
            }
        }


    },

    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser', 'storeOrder', 'appInfo']),
        ov() {
            return ov
        },
        narratorImage() {
            return this.storeOrder.narrator.image ? this.storeOrder.narrator.image : ov.DEFAULT_IMAGE
        },

        isNewOrderStarted() {
            return this.storeOrder.narrator.id || this.storeOrder.text || (this.storeOrder.file !== null) || this.isLinkValid
        },
    },

    methods: {
        showUnsavedOrder() {
            this.$store.commit('toggleOrderProtectionFromCleaning', true)
            this.$router.push(this.localePath('new-order'))
        },

        setAudioPosition(data, index) {
            var track = document.getElementById('example' + index)
            var pos = Math.floor(track.duration * data.offsetX / data.target.parentElement.offsetWidth)
            track.currentTime = pos
        },
    },


    created() {
        // console.log(this.storeOrder)
        if (!this.storeOrder) this.$store.commit('initOrder')
    },

    mounted() {
        // console.log(this.$route.query.login)
        // if (this.$route.query.login == 'yes') {
        //     this.$nextTick(function () {
        //         this.$root.$emit('open-modal', { modal: "login" })
        //     })

        // }

        // Parallax на главной
        if (window.innerWidth > 1220) {
            var parallaxElement = document.getElementsByClassName('parallax');
            new simpleParallax(parallaxElement, {
                scale: 2
            });
        }

    },


}

