
export default {
    props: ['initial_active'],

    data() {
        return {
            active: this.initial_active,
        };
    },

    methods: {
        toggleActive() {
            this.active = !this.active
        }
    }
}    
